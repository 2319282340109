<template>
    <b-card no-body>
        <b-card-body style='padding: 20px 16px;'>
            <div class="d-flex justify-content-between" v-ripple @click="isCartShown = !isCartShown"> 
                <div>
                    <span class='text-muted'>{{ selectedPlaceholder }} {{ countOfSelected }} <br>
                        <b-badge v-if="offer || specOffer" variant="success" style="background-color: #3ED085; color: #FFF; font-weight: 400;">{{$t('catalog.special-offer')}}</b-badge>
                    </span>
                    <div style='font-size: 28px; font-weight: 500;'>
                        <span>{{ (summary + guaranteeSummary).formatMoney(0," "," ") }}</span> <span class='text-muted'>$</span>
                        <span v-if="is_bonus_active" style="margin: 0 3px; color: #3ED085; font-size: 14px;  font-weight: normal"> {{bonus.discount_percent_m}}% {{$t('catalog.discount')}}</span>
                    </div>
                </div>
                <div class=" mt-auto mb-auto d-flex align-self-end" style=" border-radius: 18px; margin-left: 12px; margin-right: -8px;">
                    <feather-icon :icon=" isCartShown ? 'ChevronsUpIcon' : 'ChevronsDownIcon'" :class=" isCartShown ? 'text-primary m-auto'  : 'text-muted m-auto' " size="20" />
                </div>
            </div>
            <div v-if="bonus && !offer && !specOffer" class="d-flex justify-content-between " style="margin: 4px 0; align-items: center;">
                <div>
                    <b-img
                        src="~@/assets/images/icons/emoji/bonus.png"
                        fluid
                        alt="bonus"
                        style="width: 20px; height: 20px; margin-right: 3px; margin-left: 2px"
                    ></b-img>
                    <span class="text-muted font-weight-light">
                        {{$t('catalog.discount-available')}}
                        <feather-icon 
                            v-b-popover.hover.top="$t('popovers.referral-discount')"
                            icon="AlertCircleIcon" 
                            class="cursor-pointer info-hover text-muted" 
                        />
                    </span>
                </div>
                <b-button variant="outline-primary" :class="{'disabled': is_bonus_active }" @click="activeBonus" >{{$t('catalog.btns.use-discount')}}</b-button>
            </div>
            <b-collapse v-model="isCartShown" >
                <template v-for="item in cart">
                    <hr style='margin: 16px 0;'>
                    <div class="d-flex justify-content-between mb-1" style="padding: 0 0;">

                        <div :style="mediaViewStyle( item.miner.medias[0] )"></div>

                        <div class="d-flex flex-column justify-content-center" style="width: 100%; padding: 0px 20px;">
                            <p class="miner-name m-0">{{ item.miner.name }}</p>
                            <div class="d-flex" style="margin-top: 6px;">
                                <div style="margin-right: 16px;">{{ item.miner.hashrate }} <span class="text-muted">TH/s</span></div>
                                <div style="margin-right: 16px;">{{ item.miner.energy_usage }} <span class="text-muted">W</span></div>
                                <div style="margin-right: 16px;">{{ item.miner.energy_performance }} <span class="text-muted">J/TH</span></div>
                            </div>
                            <div  v-if="!offer && !specOffer" class="d-flex align-items-center " style="margin-top: 6px;">
                                <b-form-spinbutton
                                        size="md"
                                        inline
                                        v-model="miners.cart[item.miner.id].count"
                                        :min="0"
                                        :max="500"
                                        @change="preferCountValueChanged( $event, item.miner.id )"
                                        style="min-width: 125px;"
                                    />
                                <div style="font-size: 17px; margin-left: 6px;">
                                    {{ item.miner.price_amount.formatMoney(0," "," ") }}
                                    <span class="text-muted">$</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>

                    <p style="font-size: 15px; font-weight: 500; margin-bottom: 16px;">{{$t('catalog.guarantee.equipment')}}  
                        <feather-icon 
                            :id="`info-guarantee-${item.miner.id}`"
                            icon="AlertCircleIcon" 
                            class="cursor-pointer info-hover text-muted" 
                            style="margin-left: 6px;"
                        />
                    </p>

                    <b-popover :target="`info-guarantee-${item.miner.id}`" triggers="hover" placement="top">
                        <span>{{$t('popovers.guarantee-equipment')}}</span>
                        <br>
                        <a @click="showModal('miners')" style="text-decoration: underline;">{{$t('more')}}</a>
                    </b-popover>

                    <label class="radio-container" :class="miners.cart[item.miner.id].guarantee.ths ? 'disabled text-muted' : ''">{{$t('catalog.guarantee.1y')}} — {{(miners.cart[item.miner.id].miner.price_amount * (miners.cart[item.miner.id].miner.guarantee1y / 100)).formatMoney(0, '','')}}$
                        <input type="radio" :disabled="miners.cart[item.miner.id].guarantee.ths" value="guarantee1y" :name="`cart-radio-${item.miner.id}`" v-model="miners.cart[item.miner.id].guarantee.period" @change="calcGuarantee(item.miner.id)">
                        <span class="checkmark"></span>
                    </label>
                    <label class="radio-container" :class="miners.cart[item.miner.id].guarantee.ths ? 'disabled text-muted' : ''">{{$t('catalog.guarantee.2y')}} — {{(miners.cart[item.miner.id].miner.price_amount * (miners.cart[item.miner.id].miner.guarantee2y / 100)).formatMoney(0, '','')}}$
                        <input type="radio" :disabled="miners.cart[item.miner.id].guarantee.ths" value="guarantee2y" :name="`cart-radio-${item.miner.id}`" v-model="miners.cart[item.miner.id].guarantee.period" @change="calcGuarantee(item.miner.id)">
                        <span class="checkmark"></span>
                    </label>
                    <label class="radio-container">{{$t('catalog.guarantee.3y')}} — {{(miners.cart[item.miner.id].miner.price_amount * (miners.cart[item.miner.id].miner.guarantee3y / 100)).formatMoney(0, '','')}}$
                        <input type="radio" value="guarantee3y" :name="`cart-radio-${item.miner.id}`" v-model="miners.cart[item.miner.id].guarantee.period" @change="calcGuarantee(item.miner.id)">
                        <span class="checkmark"></span>
                    </label>
                    <label class="radio-container" :class="miners.cart[item.miner.id].guarantee.ths ? 'disabled text-muted' : ''">{{$t('catalog.guarantee.none')}}
                        <input type="radio" :disabled="miners.cart[item.miner.id].guarantee.ths" value="none" :name="`cart-radio-${item.miner.id}`" v-model="miners.cart[item.miner.id].guarantee.period" @change="calcGuarantee(item.miner.id)">
                        <span class="checkmark"></span>
                    </label>

                    <hr class="mt-1 mb-1 ml-0 mr-0" style="width: 50%">

                    <div class="d-flex">
                        <b-form-checkbox
                            :id="`cart-checkbox-${item.miner.id}`"
                            class="border-radius mb-2 pb-1"
                            v-model="miners.cart[item.miner.id].guarantee.ths"
                            :name="`cart-checkbox-${item.miner.id}`"
                            @change="calcGuarantee(item.miner.id)"
                        >
                            <span style="font-weight: 500;">{{$t('catalog.guarantee.hashrate')}} — {{(miners.cart[item.miner.id].miner.price_amount * (miners.cart[item.miner.id].miner.guaranteeths / 100)).formatMoney(0, '','')}}$</span>
                        </b-form-checkbox>
                            <feather-icon 
                                :id="`info-guarantee-hashrate-${item.miner.id}`"
                                icon="AlertCircleIcon" 
                                class="cursor-pointer info-hover text-muted" 
                                style="margin-left: 6px; margin-top: 4px"
                            />
                        <b-popover :target="`info-guarantee-hashrate-${item.miner.id}`" triggers="hover" placement="top">
                            <span>{{$t('popovers.guarantee-hashrate')}}</span>
                            <br>
                            <a @click="showModal('hashrate')" style="text-decoration: underline;">{{$t('more')}}</a>
                        </b-popover>
                    </div>

                </div>
                </template>
            </b-collapse>
        </b-card-body>

        <b-modal id="miners-modal" 
            modal-class="custom-contains guarantee-modal-mobile" 
            v-model="modalsVisibility.miners" 
            centered 
            hide-footer
        >
            <miners-modal-content @close="closeModal" />
        </b-modal>

        <b-modal id="hashrate-modal" 
            modal-class="custom-contains guarantee-modal-mobile" 
            v-model="modalsVisibility.hashrate" 
            centered 
            hide-footer
        >
            <hashrate-modal-content @close="closeModal" />
        </b-modal>
    </b-card>
</template>

<script>
    
    import miners from "@/modules/contents/miners"
    import Utils from "@/modules/utils/"
    import offers from "@/modules/contents/offers"
    import special from "@/modules/contents/special"

    import MinersModalContent from "@/views/User/miners/item/views/modals/guarantee/miners"
    import HashrateModalContent from "@/views/User/miners/item/views/modals/guarantee/hashrate"
    
    export default {
        props: {
            bonus: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                miners,
                isCartShown: false,

                is_bonus_active: false,

                modalsVisibility: {
                    miners: false,
                    hashrate: false
                },
            }
        },
        methods: {
            mediaViewStyle( media ) {
                return {
                    height: '65px',
                    width: '65px',
                    minWidth: '65px',
                    backgroundImage: `url(${media.urls.thumb})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundColor: "white",
                    borderRadius: "12px"
                }
            },

            activeBonus() {
                this.is_bonus_active = true

                this.$emit('changeStateBonus', {
                    "is_bonus_active": this.is_bonus_active
                });
            },

            preferCountValueChanged( count, miner ) {
                if( count === 0 ) {
                    this.$delete(this.miners.cart, miner);
                }
            },

            calcGuarantee(index) {
                this.miners.cart[index].guarantee.amount = 0;

                if (this.miners.cart[index].guarantee.ths) {
                    this.miners.cart[index].guarantee.period = 'guarantee3y';
                    this.miners.cart[index].guarantee.amount = this.miners.cart[index].miner.price_amount * (this.miners.cart[index].miner.guarantee3y / 100);
                    this.miners.cart[index].guarantee.amount += this.miners.cart[index].miner.price_amount * (this.miners.cart[index].miner.guaranteeths / 100);
                } else if (this.miners.cart[index].guarantee.period !== 'none') {
                    this.miners.cart[index].guarantee.amount = this.miners.cart[index].miner.price_amount * (this.miners.cart[index].miner[this.miners.cart[index].guarantee.period] / 100);
                }
                
            },

            closeModal( action ) {
                
                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
            },
            
            showModal( action ) {

                Object.keys(this.modalsVisibility).forEach(key => {
                    this.modalsVisibility[key] = false;
                });
                
                if( this.modalsVisibility.hasOwnProperty(action) ) {
                    this.modalsVisibility[action] = true;
                }
                
            },
        },
        components: {
            HashrateModalContent,
            MinersModalContent
        },
        computed: {
              
            offer() {
                if( this.$route.query['with-offer'] ) {
                    return offers.items.find(item => item.id === parseInt(this.$route.query['with-offer']) );
                }                
                return null;
                
            },

            specOffer() {
                let result = null;

                if( this.$route.query['with-offer'] ) {
                    result = special.items.find(item => item.id === parseInt(this.$route.query['with-offer']) );
                }  
                
                if( this.$route.query['with-offer'] && !result) {
                    result = special.individual_items.find(item => item.id === parseInt(this.$route.query['with-offer']) );
                }   
                
                return result;
                
            },

            summary() {

                if( this.offer && this.offer.miner ) {
                    return this.offer.price_amount;
                }

                if( this.specOffer && this.specOffer.miner ) {
                    return this.specOffer.price_amount;
                }
                
                
                let summ = 0;
                
                for(var i = 0; i < this.cart.length; i++ ) {
                    
                    let item = this.cart[i];
                    
                    for( var j = 0; j < item.count; j++ ) {
                        summ += item.miner.price_amount;
                    }
                    
                }

                if (this.is_bonus_active && this.bonus) {

                    let discount = (summ * this.bonus.discount_percent_m) / 100

                    return summ - discount

                }
                
                return summ;
            },

            guaranteeSummary() {
                   
                let summ = 0;
                
                for(var i = 0; i < this.cart.length; i++ ) {
                    
                    let item = this.cart[i];
                    
                    for( var j = 0; j < item.count; j++ ) {
                        summ += item.guarantee.amount;
                    }
                    
                }
                
                return summ;
            },
            
            selectedPlaceholder() {
                let count = 0;
                let decl = [ 'Выбран', 'Выбрано', 'Выбрано' ];

                if (this.$i18n.locale !== 'ru') decl = ['Selected', 'Selected', 'Selected'];

                for(var i = 0; i < this.cart.length; i++ ) {

                    let item = this.cart[i];

                    count = count +  item.count;

                }

                return Utils.declOfNum(count, decl);             
            },
            
            countOfSelected() {
                // return this.cart.length + ' ' + Utils.declOfNum(this.cart.length, [ 'майнер', 'майнера', 'майнеров' ]); 
                let count = 0;
                let decl = [ 'майнер', 'майнера', 'майнеров' ];

                if (this.$i18n.locale !== 'ru') decl = ['miner', 'miners', 'miners'];

                for(var i = 0; i < this.cart.length; i++ ) {

                    let item = this.cart[i];

                    count = count +  item.count;

                }

                return count + ' ' + Utils.declOfNum(count, decl);              
            },
            
            cart() {
                // if( this.offer && this.offer.miner ) {
                //     return [{
                //         miner: this.offer.miner,
                //         count: 1
                //     }];
                // }

                // if( this.specOffer && this.specOffer.miner ) {
                //     return [{
                //         miner: this.specOffer.miner,
                //         count: 1
                //     }];
                // }

                return Object.values( this.miners.cart );
            },
        },
        watch: {

        },
        mounted() {

        }

    }

</script>