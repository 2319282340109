<template>
    <b-overlay
      
      class="create-contract-view"
      :show="is_loading"
      variant='transparent'
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
        
        <showAt breakpoint="large">
            <desktop @submit="submit" :bonus="bonus"/>
        </showAt>
        
        <showAt breakpoint="medium">
            <tablet @submit="submit" :bonus="bonus"/>
        </showAt>
        
        <showAt breakpoint="small">
            <mobile @submit="submit" :bonus="bonus"/>
        </showAt>
        
        <b-modal
            ref="modal"
            size="lg"
            id="contract-details-modal"
            centered 
            hide-footer
            @hide="modalWillHide"
          >
              
             <b-card-body v-if="contract" style='padding: 0 16px 0 16px;'>
                <b-card-title class="font-weight-bolder">
                    {{$t('contracts.modal.contract-payment')}} <b-link :to="{ name: 'user-contract-details', params: { id: contract.guuid } }">№{{ contract.guuid }}</b-link>
                </b-card-title> 

                <!--<hr style="padding: 0 16px;">-->
             
                <miner-cart-card v-for="miner in contract.cart" :item="miner" :compact="true">
                    <template slot="action">
                        <div style="align-self: center; padding: 0 0px;">
                            <b-badge size="lg" class="text-black" style="font-size: 14px; padding: 8px 12px; border-radius: 40px; text-transform: none;">{{ miner.count }} {{$t('contracts.modal.piece')}}</b-badge>
                        </div>
                    </template>
                </miner-cart-card>
                        
                <!--<hr style="padding: 0 16px;">-->
                
                <div v-if="contract.invoice.type === 'btc'" ref="container" style="padding: 16px 0;">
                    
                    <div style="padding-bottom: 16px;">
                        <p class='text-muted' style='font-size: 13px; line-height: 17px;'>
                            {{$t('miners.transfer-bitcoin')}} 
                        </p>
                        <p class='text-muted' style='font-size: 13px; line-height: 17px;'>
                            {{$t('miners.payment-credited')}} 
                        </p>
                        <p class='text-muted' style='font-size: 13px; line-height: 17px;'>
                           {{$t('miners.sending-coin')}} 
                        </p>

                        <p v-if="contract.is_anonymous" class="text-muted" style="font-size: 13px; line-height: 17px;"> 
                            {{$t('contracts.modal.based-payment-contract-offer')}}
                            <b-link to="/documents?show=public-offer&type=2" target='_blank'>{{$t('contracts.modal.public-offer')}}</b-link>.</p>
                        <p v-else class="text-muted" style="font-size: 13px; line-height: 20px;"> {{$t('miners.contract-occurs')}} </p>
                    </div>
                    
                    <div class="data-form">
                        <b-form-group>
                            <span class="text-muted" style="font-size: 12px;">{{$t('contracts.modal.payment-amount')}}</span>
                            <p class="m-0"> {{ (contract.invoice.btc).decimalBtcMoney() }} ₿ </p>
                        </b-form-group>
                    </div>
                    
                    <div class="d-flex justify-content-between">
            
                        <div style="margin-right: 26px;">
                            <b-form-group class="form-group-with-label">
                                <div class="d-flex justify-content-between"> 
                                    <div ref="container" class="wordwrap">
                                        <span class="text-muted" style="font-size: 12px;">{{$t('contracts.modal.address-payment')}}</span>
                                        <p style="font-size: 16px; font-weight: 500; margin: 0; word-break: break-all;">{{ contract.invoice.identificator }}</p>
                                    </div>
                                    <div v-ripple @click="copyBitcoinAddress" style="margin: auto 12px; margin-right: 0;">
                                        <feather-icon icon="CopyIcon" size="20" class="text-muted cursor-pointer" />
                                    </div>
                                </div>
                            </b-form-group>
                        </div>

                        <showAt breakpoint="large">
                            <div>
                                <qrcode :value="qr_code_value" :options="{ width: 80, margin: 0 }"></qrcode>
                            </div>
                        </showAt>

                    </div>
                    
                    <b-row>
                        <b-col cols="12" lg="6" md="6" style="margin-top: 16px;">
                           <b-button @click="closeModal" replace size="lg" pill variant="gray" block style='padding-left:0; padding-right: 0;'>{{$t('pay-later')}}</b-button>
                        </b-col>
                        <b-col cols="12" lg="6" md="6" style="margin-top: 16px;">
                           <b-button :to="{ name: 'user-wallet', query: { act: 'output', to: contract.invoice.identificator, amount: (contract.invoice.btc - contract.invoice.unconfirmed - contract.invoice.received).decimalBtcMoney() } }" replace size="lg" pill variant="primary" block style='padding-left:0; padding-right: 0;'>{{$t('contracts.modal.pay-from-wallet')}}</b-button>
                        </b-col>
                    </b-row>
                    
                </div>
                
                <div v-else style="margin-top: 16px;">
                    <b-row>
                        <b-col></b-col>
                        <b-col class="text-right">
                            <span class="text-muted">{{$t('contracts.modal.payment-amount')}}</span>
                            <p class="m-0" style="font-size: 28px; padding-top: 6px; font-weight: 600;">{{ contract.investments.formatMoney(0, " ", " ") }} <span class="text-muted">$</span></p>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 16px;">
                        <b-col>
                           <b-button @click="closeModal" replace size="lg" pill variant="gray" block style='padding-left:0; padding-right: 0;'>{{$t('pay-later')}}</b-button>
                        </b-col>
                        <b-col>
                           <b-button @click="closeModal" replace size="lg" pill variant="primary" block style='padding-left:0; padding-right: 0;'>{{$t('pay-online')}}</b-button>
                        </b-col>
                    </b-row>
                </div>
                
             </b-card-body>
             
        </b-modal>
        
    </b-overlay>
</template>

<script>

    import miners from "@/modules/contents/miners"
    import MinerCartCard from "@/components/MinerCartCard/"
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    
    import desktop from "./desktop"
    import tablet from "./tablet"
    import mobile from "./mobile"
    
    export default {

        data() {
            return {
                miners,
                contract: null,
                is_loading: false,

                bonus: null
            }
        },
        methods: {
            
            closeModal() {
                
                this.$refs.modal.hide();
            },
            
            modalWillHide() {
                this.$router.replace({ 
                    name: 'user-contract-details', 
                    params: { 
                        id: this.contract.guuid 
                    } 
                });
            },
            
            copyBitcoinAddress( args ) {
                
                let container = this.$refs.container;
                this.$copyText( this.contract.invoice.identificator, container );
                
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: this.$t('toasts.titles.copy'),
                      text: this.$t('toasts.text.btc-address'),
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    }
                });  
            },
            
            submit( params ) {
                this.is_loading = true;
                
                if( this.$route.query['with-offer'] ) {
                    params['offer'] = this.$route.query['with-offer'];
                    params['is_bonus_active'] = false;
                }

                if (localStorage.getItem("utmTags")) {
                    params['utm_tags'] = JSON.parse(localStorage.getItem("utmTags"))
                }
                this.$request.post("contracts.order", params ).then( contract => {
                    this.$set( this.miners, "cart", {} );
                    this.is_loading = false;
                    // localStorage.removeItem('utmTags');
                    this.contract = contract;
                    this.$refs.modal.show();
                }).catch( err => {
                    this.$set( this.miners, "cart", {} );
                    this.is_loading = false;
                });
                
            },

            getBonus() {
                this.$request.get("user.bonus")
                .then( result => {
                    this.bonus = result
                });   
            }
        },
        components: {
            desktop,
            tablet,
            mobile,
            
            MinerCartCard,
            
        },
        
        computed: {
            
            qr_code_value() {
                let result = `bitcoin:${this.contract.invoice.identificator}`;                
                return result;
            }
            
        },
        
        watch: {

        },
        mounted() {
            this.getBonus()
        }

    }

</script>