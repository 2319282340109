<template>
    <div class="mt-3">
                        
        <b-tabs v-model="ownerType" pills class="gray-nav">
            <b-tab :title="$t('forms.individual')">
                <account-personal ref="personal" :isModalView="true" @changePersonal="changePersonal" />
            </b-tab>
            <b-tab v-if="personal.country.id == 181" :title="$t('forms.entity')">
                <account-entity ref="entity"  :isModalView="true" />
            </b-tab>
        </b-tabs>
        
    </div>
</template>

<script>

    import AccountPersonal from "@/views/User/account/types/individual"
    import AccountEntity from "@/views/User/account/types/entity"

    export default {

        data() {
            return {
                ownerType: 0,

                personal: {
                    
                    last_name: "",
                    first_name: "",
                    middle_name: "",
                    
                    gender: { 
                        id: 1, 
                        name: this.$i18n.locale === 'ru' ? "Мужской" : "Female" 
                    },
                    
                    country: {
                        id: 181,
                        name: "Россия",
                        phonecode: 7
                    },
                    
                    birthday: "",
                    
                    passport_series: "",
                    passport_number: "",
                    passport_date_of_issue: "",
                    passport_department_code: "",
                    passport_issued_by: "",
                    
                    phone_number: "",
                    
                    inn: ""
                },
                passport: []
            }
        },
        methods: {
            changeOwnerType( newType ) {
                this.ownerType = newType;
            },
            
            getFormData() {              
                if( this.ownerType === 0 ) {
                    var params = this.$refs.personal.personal;
                    params.passport_media = this.$refs.personal.passport;
                } else {
                    var params = {
                        company: this.$refs.entity.company,
                        bank: this.$refs.entity.bank
                    }
                }
                
                return {
                    type: this.ownerType,
                    data: params
                }
                
            },
            
            validate() {
                return new Promise((resolve, reject) => {
                   let owner =  this.ownerType === 0 ? this.$refs.personal : this.$refs.entity;
  
                   owner.validate().then(success => {
                        if (owner.is_need_save === "is_can_save") {
                           if (this.ownerType === 0 && owner.passport.length > 0 || this.ownerType === 1)
                           owner.submit()
                        }
                        resolve( success ); 
                   });
                });
                
            },

            changePersonal(personal, passport) {
                this.personal = personal
                this.passport = passport
            }
        },
        components: {
            AccountPersonal,
            AccountEntity
        },
        watch: {

        },
        mounted() {

        }

    }

</script>