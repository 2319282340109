<template>
    
    <b-row>

        <b-col cols="7">
            <b-card nob-body>

                <b-card-title>{{$t('contracts.type')}}</b-card-title>

                <b-row>
                    <b-col md="6" cols="12">
                        <div :class=" type === 1 ? 'card-picker active' : 'card-picker' " @click="changeType(1)">
                            <div class="account-icon" style="width: 32px; height: 32px; background-size: 32px;" />
                            <p class="header">{{$t('contracts.official')}}</p>
                            <span class="text">{{$t('contracts.official-text')}}</span>
                        </div>
                    </b-col>
                    <b-col md="6" cols="12">
                        <div :class=" type === 2 ? 'card-picker active' : 'card-picker' " @click="changeType(2)">
                            <div class="annon-icon" style="width: 32px; height: 32px; background-size: 32px;" />
                            <p class="header">{{$t('contracts.anonymous')}}</p>
                            <span class="text">{{$t('contracts.anonymous-text')}}</span>
                        </div>
                    </b-col>
                </b-row>

                <form-owner v-if="type === 1" ref="owner" />

                 <hr class="mt-2 mb-2">

                <b-card-title>{{$t('contracts.payment-methods')}}</b-card-title>

                <b-row>
                    <b-col md="6" cols="12">
                        <div :class=" paymentType === 'card' ? 'card-picker active' : 'card-picker' " @click="changePaymentType('card')" style='opacity: 0.55; cursor: not-allowed !important; pointer-events: none;'>
                            <div :class="{
                               'icon-bank-card' : paymentType !== 'card',
                               'icon-bank-card-active' : paymentType === 'card'
                            }" style="width: 32px; height: 32px; background-size: 32px;" />
                            <p class="header text-danger">{{$t('contracts.bank-card')}}</p>
                            <span class='text text-danger'>
                                {{$t('contracts.maximum-amount-card')}}
                                <br>
                                {{$t('contracts.larger-amounts')}}
                            </span>
                        </div>
                    </b-col>
                    <b-col md="6" cols="12">
                        <div :class=" paymentType === 'btc' ? 'card-picker active' : 'card-picker' " @click="changePaymentType('btc')">
                            <div :class="{
                               'icon-btc' : paymentType !== 'btc',
                               'icon-btc-active' : paymentType === 'btc'
                            }" style="width: 32px; height: 32px; background-size: 32px;" />
                            <p class="header">Bitcoin</p>
                            <span class="text">{{$t('contracts.using-btc')}}</span>
                        </div>
                    </b-col>
                </b-row>

                <div>
                    <hr class="mt-2 mb-2">
                    <b-row>
                        <b-col md="8" cols="12">
                            <p v-if="type == 2" class="text-muted" style="font-size: 12px; line-height: 20px;">
                                <i18n path="contracts.calculation-conditions" tap="span"> 
                                </i18n> 
                                <br>
                                {{$t('contracts.clicking-create')}}, {{$t('contracts.you-agree')}}
                                <b-link to="/documents?show=public-offer&type=2" target="_blank">{{$t('contracts.public-offer')}}</b-link>.
                            </p>
                            <p v-else class="text-muted" style="font-size: 12px; line-height: 20px;">
                                {{$t('contracts.clicking-create')}} , 
                                <br>
                                <i18n path="contracts.you-agree-doc" tap="span"> 
                                    <br>
                                </i18n>
                            </p>
                        </b-col>

                        <b-col md="4" cols="12" class="d-flex p-0 m-0">
                            <b-button @click="createContract" size="lg" block variant="primary" class="ml-auto p-0" style="font-size: 16px; height: 56px; border-radius: 16px; font-weight: 500;">
                                {{$t('contracts.btns.create')}}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
        </b-col>

        <b-col cols="5">
            <cart-desktop @changeStateBonus="changeStateBonus" :bonus="bonus"/>
        </b-col>

    </b-row>
        
</template>

<script>

    import FormOwner from "@/views/User/contracts/create/form-owner"
    import CartDesktop from "./widgets/cart.desktop"
    import miners from "@/modules/contents/miners"

    export default {
        props: {
            bonus: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                type: 2,
                paymentType: 'btc',
                is_loading: false,

                is_bonus_active: false
            }
        },
        methods: {
            
            changeStateBonus( args ) {
                this.is_bonus_active = args.is_bonus_active
            },
            
            createContract( args ) {
                if( this.type === 1 ) {
                    this.getFormData().then( result => {
                        this.$emit("submit", result );
                    });        
                } else {
                    this.$emit("submit", {
                        type: this.type,
                        paymentType: this.paymentType,
                        items: Object.values( miners.cart ).map(item => {
                            debugger
                            return {
                                id: item.miner.id,
                                count: item.count,
                                guarantee: item.guarantee
                            };
                        }),
                        is_bonus_active: this.is_bonus_active
                    });
                }
                
            },
            
            getFormData() {
                
                return new Promise(( resolve, reject ) => {
                     this.$refs.owner.validate().then(success => {
                        if( success ) {
                            if (this.$refs.owner.ownerType === 0 && this.$refs.owner.passport.length == 0) {
                                reject();
                            } else {
                                resolve({
                                    type: this.type,
                                    paymentType: this.paymentType,
                                    personalData: this.$refs.owner.getFormData(),
                                    items: Object.values( miners.cart ).map(item => {
                                        return {
                                            id: item.miner.id,
                                            count: item.count,
                                            guarantee: item.guarantee
                                        };
                                    })
                                });
                            }
                        } else {
                            reject();
                        }
                    });
                });
                
            },
            
            changePaymentType( newType ) {
                
                if( newType === 'card' ) {
                    return;
                }
                
                if( this.getInvestments() >= 20000 ) {
                   return;
                }
                
                this.paymentType = newType;
                
            },
            
            changeType( newType ) {
                
                this.type = parseInt(newType);
                
//                if( this.getInvestments() >= 20000 ) {
//                    this.paymentType = 'btc'; 
//                } else {
//                    this.paymentType = 'card'; 
//                }
                               
                this.$emit("contract-type-changed", {
                    type: this.type
                });
                
            },
        },
        components: {
            FormOwner,
            CartDesktop
        },
        watch: {

        },
        mounted() {
        }

    }

</script>